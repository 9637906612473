// src/components/EmailDetail.js
import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from 'react-to-print';
import { ENDPOINT } from '../config';


function removeHtmlTags(content) {
  const tagPattern = /<\/?[^>]+>/gi;
  return content?.replace(tagPattern, '');
}



function highlightWords(htmlString, words, type) {
  console.log('htmlString', words);
  if (!htmlString || !words || words.length === 0 || words[0] === '' || type === 'title') {
    return htmlString; // Return original string if no words to highlight
  }

  // Escape special characters for the words to safely use in regex
  const escapedWords = words.map(word => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));

  // Create a regex pattern to match any part of the words
  const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');

  // Replace matched words with highlighted versions
  return htmlString.replace(regex, match => `<span style="background-color: green;">${match}</span>`);
}

function EmailDetail({ email_data, searchWords, searchType}) {
  console.log('asadasds', email_data)
  const [email, setEmail] = useState(null);
  useEffect(() => {
    if (!email_data) {
      fetchEmail()
    }
    else{
      setEmail(email_data)
    }
    
  }, [email_data])
  

  const componentRef = useRef();
  const { id } = useParams();

  const fetchEmail = async () => {
    console.log('id', id)
    
    const response = await fetch(`${ENDPOINT}/emails/get-email/${id}`)
    const data = await response.json();
    setEmail(data?.data)
  }
  

  function getFirstHashString(input) {
    if (!input) {
      return "No Title Found";
    }
    // Split the input string into an array of lines
    const lines = input?.split('\n');

    // Loop through each line
    for (let line of lines) {
      // Check if the line starts with a hash followed by a space
      if (line.startsWith('# ')) {
        // Return the line without the hash and the following space
        return line.slice(2)?.trim();
      }
    }

    // Return null if no line starts with a hash
    return "No Title Found";
  }


  
 

  return (
    <div className="email-detail print-container" ref={componentRef}>
      {email ? (
        <>
          <div className="email-header">
            <h2>{getFirstHashString(email?.original)}</h2>
            <div>
              <CopyToClipboard text={removeHtmlTags(email?.original)}
                onCopy={() => toast.success("Copied!", {
                  position: "bottom-left",
                  autoClose: 2000
                })}>
                <button className="tooltip-button">
                  <div className="fa fa-copy"></div>
                  <span className="tooltip-text">Copy</span>
                </button>
              </CopyToClipboard>


              {/* <button className="copy-button" onClick={handleShareByEmail}>
              <div class="fa fa-share"></div>
            </button> */}
              <ReactToPrint
                trigger={() => <button className="tooltip-button">

                  <div className="fa fa-print"></div>
                  <span className="tooltip-text">Print</span>
                </button>}
                content={() => componentRef.current}
              />


            </div>


          </div>

          <div>

          </div>
          <div dangerouslySetInnerHTML={{ __html: `<pre>${highlightWords(email?.content, searchWords, searchType)}</pre>` }} />
        </>
      ) : (
        <p style={{margin:'auto', textAlign:'center'}}>Select an email to view details</p>
      )}
      <ToastContainer />

    </div>
  );
}

export default EmailDetail;
